import MailIcon from '@mui/icons-material/Mail'
import { Badge, Box, Button, Card, CardContent, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useContext, useState } from 'react'
import './EnquiryItem.css'
import NiceModal from '@ebay/nice-modal-react'
import { Lock, LockOpenOutlined, LockOutlined, Message, MessageOutlined } from '@mui/icons-material'
import { useIntersectionObserver } from '@uidotdev/usehooks'
import { GlobalContext } from '../context/GlobalContext'
import useApi from '../hooks/useApi'
import haxios from '../lib/haxios'
import { Spinner } from './gsys-ui/Spinner/Spinner'

dayjs.extend(relativeTime)

const EnquiryItem = ({ enquiry, onMessageClick, refetch }) => {
  const [isModalLoading, setModalLoading] = useState(false)
  const [ref, entry] = useIntersectionObserver({ threshold: 0.1 })
  const isIntersecting = !!entry?.isIntersecting
  const { cinfo } = useContext(GlobalContext)

  const isRegPlate = enquiry.Reg && enquiry.Reg !== 'CONSUMABLES'

  const today = dayjs().startOf('day')
  const createdDate = dayjs(enquiry.DatetimeCreated)
  const modifiedDate = dayjs(enquiry.DatetimeModified)

  const isCreatedToday = createdDate.isSame(today, 'day')
  const isModifiedToday = modifiedDate.isSame(today, 'day')

  const formattedCreatedDate = isCreatedToday
    ? 'Today, ' + createdDate.format('HH:mm')
    : createdDate.format('DD/MM/YYYY, HH:mm')
  const formattedModifiedDate = isModifiedToday
    ? 'Today, ' + modifiedDate.format('HH:mm')
    : modifiedDate.format('DD/MM/YYYY, HH:mm')

  const timeSinceCreated = createdDate.fromNow()
  const timeSinceModified = modifiedDate.fromNow()

  const handleShowEnquiry = async () => {
    // it's important to check like this to resolve race conditions (or reduce them anyway)
    // it's possible that two people click simultaneously and both receive unlocked status,
    // then both try to lock the enquiry,
    // but only one gets lock. so, we have to check a second time after the lock request.
    const lockedDataFirst = await haxios.get(
      `https://api.aa-vision.com/api/connectplus/factor/enquiry/${enquiry.EnquiryId}`
    )

    if (lockedDataFirst.Locked === false) {
      setModalLoading(true)

      await haxios.get(`https://api.aa-vision.com/api/connectplus/factor/enquiry/lock/${enquiry.EnquiryId}`)

      const lockedDataSecond = await haxios.get(
        `https://api.aa-vision.com/api/connectplus/factor/enquiry/${enquiry.EnquiryId}`
      )

      NiceModal.show('enquiryDetailsModal', {
        initialLockedData: lockedDataSecond,
        enquiry,
        currentUser: cinfo.Username,
        refetchEnquiries: refetch
      })
    } else {
      NiceModal.show('enquiryDetailsModal', {
        initialLockedData: lockedDataFirst,
        enquiry,
        currentUser: cinfo.Username,
        refetchEnquiries: refetch
      })
    }

    setModalLoading(false)
    refetch()
  }

  return (
    <div ref={ref} className="py-3 last:border-none!" style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ paddingRight: 1 }}>
              <StatusChip status={enquiry.Status} />
            </Box>
            <Box sx={{ paddingRight: 2 }}>
              <DocumentChip document={enquiry.Document} />
            </Box>
            <Box sx={{ marginRight: 1 }}>
              <LockedChip isIntersecting={isIntersecting} enquiry={enquiry} />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {`${enquiry.CustomerKeyCode} - ${enquiry.GarageName}`}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2 }}>
            <MessagesIcon
              messageCount={enquiry.MessageCount}
              factorUnread={enquiry.FactorUnread}
              onMessageClick={() =>
                NiceModal.show('enquiryDetailsModal', {
                  initialTab: 1,
                  enquiry
                })
              }
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ paddingRight: 2 }}>
              {isRegPlate ? <RegPlate size={1.08}>{enquiry.Reg}</RegPlate> : <ConsumablesBox />}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                {enquiry.Parts}
              </Typography>
              <Typography variant="caption">{enquiry.Notes || 'No additional notes provided.'}</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 1
          }}
        >
          <Box>
            <Box sx={{ lineHeight: 1 }}>
              <Typography variant="caption" sx={{ lineHeight: 1 }}>
                <span style={{ fontWeight: 600 }}>Created:</span> {formattedCreatedDate} ({timeSinceCreated})
              </Typography>
            </Box>
            <Box sx={{ lineHeight: 1 }}>
              <Typography variant="caption" sx={{ lineHeight: 1 }}>
                <span style={{ fontWeight: 600 }}>Modified:</span>{' '}
                {formattedModifiedDate === 'Invalid Date' ? 'Never' : formattedModifiedDate}{' '}
                {formattedModifiedDate === 'Invalid Date' ? '' : `(${timeSinceModified})`}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Button variant="outlined" onClick={handleShowEnquiry}>
              {isModalLoading ? 'Locking...' : 'View details'}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export const LockedChip = ({ isIntersecting, enquiry }) => {
  const {
    isLoading,
    isFetching,
    data: lockedData,
    refetch
  } = useApi({
    url: `https://api.aa-vision.com/api/connectplus/factor/enquiry/${enquiry.EnquiryId}`,
    method: 'get',
    refetch: 15 * 1000,
    enabled: isIntersecting,
    initial: {
      Locked: enquiry.Locked,
      PortalLockUsername: null
    }
  })

  const isLocked = lockedData?.Locked
  const username = lockedData?.PortalLockUsername

  return (
    <Box>
      <Tooltip title={`This enquiry is ${isLocked ? '' : 'un'}locked.`}>
        <Box
          sx={{
            background: isLocked ? 'orange' : 'green',
            color: 'white',
            display: 'inline-flex',
            borderRadius: '16px',
            height: '32px',
            minWidth: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 4px'
          }}
        >
          {isLocked ? <LockOutlined /> : <LockOpenOutlined />}
          {isLocked ? (
            isLoading ? (
              <Spinner color="rgba(255,255,255,0.8)" size={18} />
            ) : (
              <Box sx={{ fontSize: '14px', fontWeight: 600 }}>{username}</Box>
            )
          ) : null}
        </Box>
      </Tooltip>
    </Box>
  )
}

export const UnlockedChip = () => {
  return (
    <Box>
      <Tooltip title="This enquiry is locked.">
        <Box
          sx={{
            background: 'green',
            color: 'white',
            display: 'inline-flex',
            borderRadius: '16px',
            height: '32px',
            minWidth: '32px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LockOpenOutlined />
        </Box>
      </Tooltip>
    </Box>
  )
}

export const StatusChip = ({ status }) => {
  const colors = {
    'Waiting': '#FFA500',
    'Ordered': '#28A745',
    'Order': '#10431c',
    'Quoted': 'dodgerblue',
    'Garage Confirmed': 'pink',
    'Cancelled': 'red'
  }

  const labels = {
    'Waiting': 'Quote',
    'Ordered': 'Ordered',
    'Quoted': 'Quoted',
    'Order': 'Order',
    'Garage Confirmed': 'Confirmed',
    'Cancelled': 'Cancelled'
  }

  return (
    <Chip
      label={labels[status]}
      sx={{
        'backgroundColor': colors[status] || '#28A745',
        'color': '#fff',
        'fontWeight': 'bold',
        'textAlign': 'center',
        'fontSize': '0.9em',
        'width': '96px',
        'userSelect': 'none',
        '& .MuiChip-label': {
          paddingLeft: 1,
          paddingRight: 1
        }
      }}
    />
  )
}

export const DocumentChip = ({ document }) => {
  return (
    <Chip
      label={document}
      sx={{
        backgroundColor: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '0.9em',
        padding: '8px 0',
        width: '70px',
        border: '1px solid rgba(0,0,0,0.2)'
      }}
    />
  )
}

export const RegPlate = ({ children, size = 1 }) => {
  return (
    <div
      className="flex justify-center items-center rounded-md border border-black flex-0"
      style={{
        backgroundColor: 'rgb(248, 208, 56)',
        width: `${160 * size}px`,
        height: `${40 * size}px`,
        fontSize: `${27 * size}px`,
        fontFamily: 'LicensePlate',
        borderColor: 'hsl(47.5, 93.2%, 45%)',
        overflow: 'hidden'
      }}
    >
      {children}
    </div>
  )
}

export const ConsumablesBox = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#444',
        borderRadius: '6px',
        textAlign: 'center',
        fontWeight: 600,
        color: '#FFFFFF',
        width: 168,
        height: 42,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      CONSUMABLES
    </Box>
  )
}

const MessagesIcon = ({ messageCount, factorUnread, onMessageClick }) => {
  const plural = messageCount !== 1

  return (
    messageCount > 0 && (
      <Tooltip title={`${messageCount} message${plural ? 's' : ''}, ${factorUnread} unread`}>
        {factorUnread > 0 ? (
          <Badge badgeContent={`${factorUnread}`} color="error">
            <IconButton onClick={onMessageClick}>
              <MessageOutlined sx={{ color: 'rgba(0,0,0,0.5)' }} />
            </IconButton>
          </Badge>
        ) : (
          <IconButton onClick={onMessageClick}>
            <MessageOutlined sx={{ color: 'rgba(0,0,0,0.5)' }} />
          </IconButton>
        )}
      </Tooltip>
    )
  )
}

export default EnquiryItem
