import { useRef } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Icon } from '@mui/material'

const LightboxModal = NiceModal.create(({ url }) => {
  const containerRef = useRef(null)
  const modal = useModal()

  const handleOnClick = (e) => {
    //if (e.target !== containerRef.current) return
    modal.remove()
  }

  return (
    <div
      className="fixed inset-0 z-50 p-20"
      style={{ backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1201 }}
      ref={containerRef}
      onClick={handleOnClick}
    >
      <div className="absolute top-4 right-4 p-4 text-gray-400 bg-black bg-opacity-0 rounded-full transition-colors cursor-pointer hover:bg-opacity-50 hover:text-white">
        <Icon>close</Icon>
      </div>
      <img className="object-contain w-full h-full" src={url} alt="close" />
    </div>
  )
})

export default LightboxModal
