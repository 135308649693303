import { useEffect, useState } from 'react'
import haxios from '../lib/haxios'

const useApi = ({ url, method = 'get', data: body, refetch = null, enabled = true, initial = null }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(initial)

  const loadData = async () => {
    let res
    setIsFetching(true)

    if (method.toLowerCase() === 'get') {
      res = await haxios.get(url, {
        params: body
      })
    } else if (method.toLowerCase() === 'post') {
      res = await haxios.post(url, body)
    }

    setIsFetching(false)
    setIsLoading(false)
    setData(res)
  }

  useEffect(() => {
    if (enabled === true) loadData()
  }, [url, JSON.stringify(body), enabled])

  useEffect(() => {
    if (!refetch) return

    const interval = setInterval(() => {
      if (enabled === true) loadData()
    }, refetch)

    return () => clearInterval(interval)
  }, [refetch, JSON.stringify(body), enabled])

  useEffect(() => {
    if (enabled === true) setIsLoading(true)
  }, [enabled])

  return {
    isLoading,
    isFetching,
    data,
    refetch: loadData
  }
}

export default useApi
