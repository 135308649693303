import AssessmentIcon from '@mui/icons-material/Assessment'
import HomeIcon from '@mui/icons-material/Home'
import PeopleIcon from '@mui/icons-material/People'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import RedeemIcon from '@mui/icons-material/Redeem'
import SettingsIcon from '@mui/icons-material/Settings'
import WorkIcon from '@mui/icons-material/Work'
import { Box, Drawer as MuiDrawer, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 220

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
      }
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
      }
    }
  ]
}))

const Sidebar = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate()

  const menuItems = [
    { text: 'Enquiries', icon: <HomeIcon />, path: '/enquiries' },
    { text: 'Dashboard', icon: <QuestionAnswerIcon />, path: '/dashboard' },
    { text: 'Rewards', icon: <RedeemIcon />, path: '/rewards' },
    { text: 'Customers', icon: <PeopleIcon />, path: '/customers' },
    { text: 'Staff', icon: <WorkIcon />, path: '/staff' },
    { text: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/settings' }
  ]

  return (
    <Drawer variant="permanent" open={isOpen}>
      <Box sx={{ paddingTop: 9, width: 240 }}>
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.path}
              onClick={() => {
                navigate(item.path)
                toggleDrawer()
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

export default Sidebar
