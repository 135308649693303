import NiceModal from '@ebay/nice-modal-react'
import {
  Box,
  Button,
  Card,
  Input,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import useApi from '../../hooks/useApi'
import { ModalContainerAnimateDown } from '../gsys-ui/ModalContainer/ModalContainer'
import moment from 'moment'
import haxios from '../../lib/haxios'
import { successToast } from '../../lib/util'

const VrmUsageModal = NiceModal.create(({ acct, limit, allowed, refetchUsers }) => {
  const { cinfo } = useContext(GlobalContext)
  const modalContainer = useRef(null)
  const [limitValue, setLimitValue] = useState(limit)
  const [allowedValue, setAllowedValue] = useState(allowed)
  const [changed, setChanged] = useState(false)

  const { isLoading, data, refetch } = useApi({
    url: `/connectplus/vrmUsages/${acct}`,
    data: {}
  })

  if (isLoading || !data) return null

  const handleClose = () => {
    modalContainer.current?.closeModal()
  }

  const handleSave = async () => {
    await haxios.put(`/connectplus/vrmAllowances/${acct}`, {
      Allowed: allowedValue,
      Limit: parseInt(limitValue)
    })

    await refetchUsers()
    handleClose()
    successToast(`User ${acct} has been saved successfully.`)
  }

  const handleChangeAllowed = (e) => {
    setAllowedValue(e.target.checked)
    setChanged(true)
  }

  const handleChangeLimit = (e) => {
    setLimitValue(e.target.value)
    setChanged(true)
  }

  const handleDelete = () => {
    const submit = async () => {
      await haxios.delete(`/connectplus/vrmAllowances/${acct}`)
      await refetchUsers()
      handleClose()
      successToast(`User ${acct} has been deleted successfully.`)
    }

    NiceModal.show('deleteModal', { text: "Are you sure you want to delete this user's VRM allowances?", submit })
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card className="w-[900px] h-[709px]">
        <div className="flex justify-between items-center p-4 border-b border-b-gray-300">
          <div className="text-2xl font-semibold">{acct} VRM usage</div>
          <div className="space-x-2">
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDelete} color="error">
              Delete
            </Button>
            <Button variant="contained" onClick={handleSave} disabled={!changed}>
              Save
            </Button>
          </div>
        </div>

        <div className="flex justify-evenly">
          <div className="flex-1">
            <UsageTable rows={data} />
          </div>

          <div className="flex-1 p-4 space-y-4">
            <div className="text-lg font-semibold">VRM settings</div>
            <div>
              <div className="text-sm">Limit</div>
              <Input value={limitValue} onChange={handleChangeLimit} variant="outlined" />
            </div>
            <div>
              <div className="text-sm">Allowed</div>
              <Switch checked={allowedValue} onChange={handleChangeAllowed} />
            </div>
          </div>
        </div>
      </Card>
    </ModalContainerAnimateDown>
  )
})

const UsageTable = ({ rows }) => {
  const [page, setPage] = React.useState(0)
  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rows.length)

  const visibleRows = React.useMemo(
    () => [...rows].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage]
  )

  return (
    <div className="border-r border-r-gray-300">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>VRM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{moment(row.DateTime).format('HH:mm DD/MM/YY')}</TableCell>
                <TableCell>{row.VRM}</TableCell>
              </TableRow>
            ))}
            {visibleRows.length === 0 && (
              <TableRow style={{ borderBottom: 'none', textAlign: 'center' }}>
                <TableCell colSpan={6}>No entries to display.</TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                sx={{
                  height: 53 * (visibleRows.length === 0 ? emptyRows - 1 : emptyRows),
                  borderBottom: 'none !important'
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex justify-end border-t border-t-gray-300">
        <TablePagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
    </div>
  )
}

export default VrmUsageModal
