import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../context/GlobalContext'
import haxios from '../lib/haxios'
import { useContext, useEffect, useState } from 'react'

const useAuth = () => {
  const [isLoading, setLoading] = useState(true)
  const [isAuthed, setAuthed] = useState(null)
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      const res = await checkAuth()
      setAuthed(res)
      setLoading(false)
    })()
  }, [location])

  const checkAuth = async () => {
    try {
      const res = await haxios.get('https://api.aa-vision.com/api/authenticate/expired')
      if (res.error) return false
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  return { isAuthed, authLoading: isLoading }
}

export default useAuth
