import NiceModal from '@ebay/nice-modal-react'
import { Button, Card, Icon } from '@mui/material'
import React, { useRef } from 'react'
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'

const DeleteModal = NiceModal.create(({ text, submit }) => {
  const modalContainer = useRef(null)

  const handleClose = () => {
    modalContainer.current?.closeModal()
  }

  const handleSubmit = async () => {
    await submit()
    handleClose()
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card className="w-[400px] p-4">
        <div className="flex flex-col items-center space-y-4">
          <Icon sx={{ fontSize: '80px', color: 'red' }}>error_outline</Icon>
          <div className="text-center">{text}</div>
          <div className="font-semibold text-center">This cannot be undone!</div>
          <div className="flex justify-between mt-4 w-full">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit} color="error">
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </ModalContainerAnimateDown>
  )
})

export default DeleteModal
