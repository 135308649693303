import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Icon,
  Input,
  List,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useApi from '../../hooks/useApi'
import { ModeEdit } from '@mui/icons-material'
import NiceModal from '@ebay/nice-modal-react'
import { useState } from 'react'

const VrmSettings = () => {
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const pageSize = 8

  const {
    isLoading,
    data: allowances,
    refetch
  } = useApi({
    url: '/connectplus/vrmAllowances'
  })

  const handleOpenViewModal = (acct, limit, allowed) => {
    NiceModal.show('vrmUsageModal', { acct, limit, allowed, refetchUsers: refetch })
  }

  const handleOpenAddModal = () => {
    NiceModal.show('vrmAddModal', { refetchUsers: refetch })
  }

  if (isLoading || !allowances)
    return (
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="part-list-content" id="part-list-header">
            <Typography variant="h6">VRM Allowances</Typography>
          </AccordionSummary>
        </Accordion>
      </Grid>
    )

  const allowancesFiltered = allowances.filter((row) => row.Acct.toLowerCase().includes(searchValue.toLowerCase()))
  const pageCount = Math.ceil(allowancesFiltered.length / 8)
  const canGoToNextPage = page + 1 < pageCount
  const canGoToPrevPage = page > 0

  const allowancesPage = allowancesFiltered.slice(page * pageSize, page * pageSize + 8)

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="part-list-content" id="part-list-header">
          <Typography variant="h6">VRM Allowances</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="space-x-4">
                <Button variant="contained" onClick={handleOpenAddModal}>
                  Add user
                </Button>
                <Input
                  variant="contained"
                  placeholder="Type to search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </Grid>

            {allowancesPage.map((el, ind) => (
              <Grid key={el.Acct} item xs={3}>
                <Card className="p-3">
                  <div className="text-2xl font-semibold">{el.Acct}</div>
                  <div>Allowance: {el.Limit}</div>
                  <div>
                    {el.Allowed ? (
                      <div className="font-semibold text-green-500">Enabled</div>
                    ) : (
                      <div className="font-semibold text-red-500">Disabled</div>
                    )}
                  </div>
                  <div className="flex justify-end mt-2">
                    <Button
                      variant="text"
                      startIcon={<ModeEdit />}
                      onClick={() => handleOpenViewModal(el.Acct, el.Limit, el.Allowed)}
                    >
                      View
                    </Button>
                  </div>
                </Card>
              </Grid>
            ))}

            <Grid item xs={12}>
              <div className="flex justify-end items-center space-x-2 select-none">
                <Button disabled={!canGoToPrevPage} onClick={() => setPage(page - 1)}>
                  <Icon>chevron_left</Icon>
                </Button>
                <div>
                  Page {page + 1} of {pageCount}
                </div>
                <Button disabled={!canGoToNextPage} onClick={() => setPage(page + 1)}>
                  <Icon>chevron_right</Icon>
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default VrmSettings
