import React, { useState, useEffect, useContext } from 'react'
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Sidebar from './Sidebar'
import { GlobalContext } from '../context/GlobalContext'
import haxios from '../lib/haxios'
import VrmSettings from './settings/VrmSettings'
import PartListSettings from './settings/PartListSettings'

const Settings = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [partGroups, setPartGroups] = useState([])
  const [selectedPartGroup, setSelectedPartGroup] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const [newPartGroupName, setNewPartGroupName] = useState('')
  const [newPartItems, setNewPartItems] = useState('')
  const logoUrl = 'https://www.melkshammotorspares.co.uk/images/mms-initials-logo-2019-500-002.png'

  useEffect(() => {
    fetchPartGroups()
  }, [])

  const fetchPartGroups = async () => {
    try {
      const response = await haxios.get(`/api/connectplus/partlist`)
      setPartGroups(response.data)
    } catch (error) {
      console.error('Error fetching part groups:', error)
    }
  }

  const handlePartGroupSelect = (partGroup) => {
    setSelectedPartGroup(partGroup)
    setIsEditDialogOpen(true)
  }

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false)
    setSelectedPartGroup(null)
  }

  const handlePartGroupUpdate = async () => {
    if (!selectedPartGroup) return

    try {
      await haxios.put(`/api/connectplus/partlist/update`, {
        PartGroup: selectedPartGroup.PartGroup,
        PartList: selectedPartGroup.PartList
      })
      fetchPartGroups()
      setIsEditDialogOpen(false)
    } catch (error) {
      console.error('Error updating part group:', error)
    }
  }

  const handleAddDialogOpen = () => {
    setIsAddDialogOpen(true)
  }

  const handleAddDialogClose = () => {
    setIsAddDialogOpen(false)
    setNewPartGroupName('')
    setNewPartItems('')
  }

  const handleAddPartGroup = async () => {
    try {
      const partItemsArray = newPartItems.split(',').map((item) => item.trim())
      await haxios.post(`/api/connectplus/partlist`, {
        PartGroup: newPartGroupName,
        PartList: partItemsArray
      })
      fetchPartGroups()
      handleAddDialogClose()
    } catch (error) {
      console.error('Error adding part group:', error)
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setDrawerOpen(open)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)} edge="start" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <img src={logoUrl} alt="Melksham Motor Spares Logo" style={{ width: '50px', marginRight: '15px' }} />
          <Typography variant="h6" noWrap>
            Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <Grid container spacing={3}>
          <PartListSettings />
          <VrmSettings />
        </Grid>

        {/* Dialog for Editing Part Groups */}
        <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Edit Part Group</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Part Group Name"
              value={selectedPartGroup?.PartGroup || ''}
              disabled
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Part Items (comma separated)"
              value={selectedPartGroup?.PartList.join(', ') || ''}
              onChange={(e) =>
                setSelectedPartGroup({
                  ...selectedPartGroup,
                  PartList: e.target.value.split(',').map((item) => item.trim())
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Cancel</Button>
            <Button onClick={handlePartGroupUpdate} variant="contained" color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Adding Part Groups */}
        <Dialog open={isAddDialogOpen} onClose={handleAddDialogClose}>
          <DialogTitle>Add New Part Group</DialogTitle>
          <DialogContent>
            <div className="pt-2">
              <TextField
                fullWidth
                label="Part Group Name"
                value={newPartGroupName}
                onChange={(e) => setNewPartGroupName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Part Items (comma separated)"
                value={newPartItems}
                onChange={(e) => setNewPartItems(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddDialogClose}>Cancel</Button>
            <Button onClick={handleAddPartGroup} variant="contained" color="primary">
              Add Part Group
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}

export default Settings
