import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Icon,
  Input,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useApi from '../../hooks/useApi'
import { ModeEdit } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import NiceModal from '@ebay/nice-modal-react'

const PartListSettings = () => {
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const pageSize = 8

  const {
    isLoading,
    data: partLists,
    refetch
  } = useApi({
    url: '/connectplus/partlist'
  })

  if (isLoading || !partLists) {
    return (
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="part-list-content" id="part-list-header">
            <Typography variant="h6">Part Lists</Typography>
          </AccordionSummary>
        </Accordion>
      </Grid>
    )
  }

  const handleAddPartList = () => {
    NiceModal.show('partListAddModal', { refetchPartLists: refetch })
  }

  const handleEditPartList = (pl) => {
    console.log(pl)
    NiceModal.show('partListEditModal', {
      initialGroup: pl.PartGroup,
      initialList: pl.PartList,
      refetchPartLists: refetch
    })
  }

  const partListsFiltered = partLists
    .filter((row) => row.PartGroup.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => ('' + a.PartGroup).localeCompare(b.PartGroup))
  const pageCount = Math.ceil(partListsFiltered.length / 8)
  const canGoToNextPage = page + 1 < pageCount
  const canGoToPrevPage = page > 0
  const partsListPage = partListsFiltered.slice(page * pageSize, page * pageSize + 8)

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="part-list-content" id="part-list-header">
          <Typography variant="h6">Part Lists</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="space-x-4">
                <Button variant="contained" onClick={handleAddPartList}>
                  Add part list
                </Button>
                <Input
                  variant="contained"
                  placeholder="Type to search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </Grid>

            {partsListPage.map((el, ind) => (
              <PartGroup partGroup={el} key={ind} handleEditPartList={handleEditPartList} />
            ))}

            <Grid item xs={12}>
              <div className="flex justify-end items-center space-x-2 select-none">
                <Button disabled={!canGoToPrevPage} onClick={() => setPage(page - 1)}>
                  <Icon>chevron_left</Icon>
                </Button>
                <div>
                  Page {page + 1} of {pageCount}
                </div>
                <Button disabled={!canGoToNextPage} onClick={() => setPage(page + 1)}>
                  <Icon>chevron_right</Icon>
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

const PartGroup = ({ partGroup, handleEditPartList }) => {
  const listRef = useRef(null)
  const [showOverflow, setShowOverflow] = useState(false)

  /*useEffect(() => {
    if (!listRef.current) return

    console.log(listRef.current.scrollHeight, listRef.current.clientHeight)
    if (listRef.current.scrollHeight > listRef.current.clientHeight) {
      console.log('overflow', partGroup.PartGroup)
      console.log(
        [...listRef.current.children].map((child) => ({
          offsetHeight: child.offsetTop,
          scrollHeight: listRef.current.offsetHeight
        }))
      )
      const count = [...listRef.current.children].filter((child) => child.offsetTop > listRef.offsetHeight).length
      console.log(count)
      setShowOverflow(count)
    }
  }, [])*/

  return (
    <Grid key={partGroup.Acct} item xs={3}>
      <Card className="p-3">
        <div className="mb-1 text-xl font-semibold">{partGroup.PartGroup}</div>
        <div ref={listRef} className="flex overflow-hidden flex-wrap gap-1 items-start" style={{ height: '60px' }}>
          {partGroup.PartList.map((item, ind) => (
            <div
              key={ind}
              className="inline-block px-2 py-1 text-sm font-semibold text-white whitespace-nowrap bg-blue-400 rounded-full"
            >
              {item}
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-2">
          <Button variant="text" startIcon={<ModeEdit />} onClick={() => handleEditPartList(partGroup)}>
            View
          </Button>
        </div>
      </Card>
    </Grid>
  )
}

export default PartListSettings
