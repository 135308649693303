import React, { createContext, useState } from 'react'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [apir, setApir] = useState('https://melk.connectplus.parts')
  const [cinfo, setCinfo] = useState('')
  const baseUrl = 'https://api.aa-vision.com'

  return (
    <GlobalContext.Provider
      value={{
        apir,
        setApir,
        cinfo,
        setCinfo,
        username,
        setUsername,
        password,
        setPassword,
        baseUrl
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
