import NiceModal from '@ebay/nice-modal-react'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Card, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import React, { useState, useContext, useRef, useEffect } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import useApi from '../hooks/useApi'
import { ConsumablesBox, DocumentChip, RegPlate, StatusChip } from './EnquiryItem'
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'
import haxios from '../lib/haxios'
import { getImageFromBlobId, sendMessage } from '../lib/api'
import { Spinner } from './gsys-ui/Spinner/Spinner'
import ComposeMessage from './ComposeMessage'

const EnquiryDetailsView = NiceModal.create(
  ({ initialTab = 0, initialLockedData, enquiry, currentUser, refetchEnquiries }) => {
    const { cinfo } = useContext(GlobalContext)
    const modalContainer = useRef(null)
    const [error, setError] = useState(null)
    const [tabIndex, setTabIndex] = useState(initialTab)

    const {
      isLoading: isLoadingLines,
      data: lines,
      refetch: refetchLines
    } = useApi({
      url: `https://vision-web-api-test.azurewebsites.net/api/connectplus/factor/response/${enquiry.EnquiryId}`
    })

    const {
      isLoading: isLoadingMessages,
      data: messages,
      refetch: refetchMessages
    } = useApi({
      url: `https://api.aa-vision.com/api/connectplus/factor/messages/${enquiry.EnquiryId}/false`,
      refetch: 20 * 1000,
      initial: []
    })

    const {
      isLoading: isLoadingLocked,
      data: lockedData,
      refetch: refetchLocked
    } = useApi({
      url: `https://api.aa-vision.com/api/connectplus/factor/enquiry/${enquiry.EnquiryId}`,
      method: 'get',
      refetch: 5 * 1000,
      initial: initialLockedData
    })

    if (isLoadingLines) return null

    console.log('lockedData', lockedData)

    const handleTabChange = (event, newIndex) => {
      if (newIndex === 0) refetchLines()
      if (newIndex === 1) refetchMessages()
      setTabIndex(newIndex)
    }

    const isLockedByCurrentUser = lockedData.Locked && lockedData.PortalLockUsername === currentUser
    console.log(lockedData.Locked, lockedData.PortalLockUsername, currentUser)
    const showUnlockButton = isLockedByCurrentUser || (lockedData.locked && cinfo.Admin)

    const handleUnlock = async () => {
      if (!lockedData.Locked) return
      if (!cinfo.Admin && !isLockedByCurrentUser) return

      try {
        await haxios.get(`https://api.aa-vision.com/api/connectplus/factor/enquiry/unlock/${enquiry.EnquiryId}`)
        refetchLocked()
        refetchEnquiries()
      } catch (error) {
        console.log(error)
      }
    }

    const messagesWithInitialImage = enquiry.BlobStorageId
      ? [
          {
            BlobStorageId: enquiry.BlobStorageId,
            Datetime: enquiry.DatetimeCreated,
            EnquiryId: enquiry.EnquiryId,
            Factor: false,
            FactorCompanyId: enquiry.FactorCompanyId,
            Garage: true,
            MessageId: enquiry.BlobStorageId,
            RecipientRead: false,
            Text: '',
            UserId: null,
            Username: null
          },
          ...messages
        ]
      : messages

    return (
      <ModalContainerAnimateDown ref={modalContainer}>
        <Card sx={{ width: '900px' }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <EnquiryHeader
              enquiry={enquiry}
              lockedData={lockedData}
              closeModal={modalContainer.current?.closeModal}
              showUnlockButton={showUnlockButton}
              handleUnlock={handleUnlock}
            />

            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Order Details" />
              <Tab label="Messages" />
            </Tabs>

            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              {error && <Typography color="error">{error}</Typography>}
              {tabIndex === 0 && lines && <DetailsTab lines={lines} />}
              {tabIndex === 1 && messages && (
                <MessagesTab
                  enquiry={enquiry}
                  refetch={refetchMessages}
                  messages={messagesWithInitialImage}
                  locked={lockedData.Locked}
                  isLockedByCurrentUser={isLockedByCurrentUser}
                />
              )}
            </Box>
          </Box>
        </Card>
      </ModalContainerAnimateDown>
    )
  }
)

const EnquiryHeader = ({ enquiry, lockedData, closeModal, showUnlockButton, handleUnlock }) => {
  const isRegPlate = enquiry.Reg && enquiry.Reg !== 'CONSUMABLES'

  const today = dayjs().startOf('day')
  const createdDate = dayjs(enquiry.DatetimeCreated)
  const modifiedDate = dayjs(enquiry.DatetimeModified)

  const isCreatedToday = createdDate.isSame(today, 'day')
  const isModifiedToday = modifiedDate.isSame(today, 'day')

  const formattedCreatedDate = isCreatedToday
    ? 'Today, ' + createdDate.format('HH:mm')
    : createdDate.format('DD/MM/YYYY, HH:mm')
  const formattedModifiedDate = isModifiedToday
    ? 'Today, ' + modifiedDate.format('HH:mm')
    : modifiedDate.format('DD/MM/YYYY, HH:mm')

  const timeSinceCreated = createdDate.fromNow()
  const timeSinceModified = modifiedDate.fromNow()

  return (
    <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 1 }}>
            <StatusChip status={lockedData.Status} />
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <DocumentChip document={lockedData.Document} />
          </Box>
          <Box sx={{ marginRight: 1 }}>
            <LockedChip lockedData={lockedData} />
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {`${lockedData.CustomerKeyCode} - ${lockedData.GarageName}`}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 2 }}>
            {isRegPlate ? <RegPlate size={1.05}>{lockedData.Reg}</RegPlate> : <ConsumablesBox />}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h7" sx={{ lineHeight: 1 }}>
              {lockedData.Parts}
            </Typography>
            <Typography variant="caption">{lockedData.Notes || 'No additional notes provided.'}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 1 }}>
        <Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Created:</span> {formattedCreatedDate} ({timeSinceCreated})
            </Typography>
          </Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Modified:</span>{' '}
              {formattedModifiedDate === 'Invalid Date' ? 'Never' : formattedModifiedDate}{' '}
              {formattedModifiedDate === 'Invalid Date' ? '' : `(${timeSinceModified})`}
            </Typography>
          </Box>
        </Box>

        <Box>
          {showUnlockButton && (
            <Box sx={{ display: 'inline-block', paddingRight: 2 }}>
              <Button variant="text" startIcon={<LockOpenOutlined />} onClick={handleUnlock}>
                Unlock
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const LockedChip = ({ lockedData }) => {
  const isLocked = lockedData?.Locked
  const username = lockedData?.PortalLockUsername

  return (
    <Box>
      <Tooltip title={`This enquiry is ${isLocked ? '' : 'un'}locked.`}>
        <Box
          sx={{
            background: isLocked ? 'orange' : 'green',
            color: 'white',
            display: 'inline-flex',
            borderRadius: '16px',
            height: '32px',
            minWidth: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 4px'
          }}
        >
          {isLocked ? <LockOutlined /> : <LockOpenOutlined />}
          {isLocked && <Box sx={{ fontSize: '14px', fontWeight: 600 }}>{username}</Box>}
        </Box>
      </Tooltip>
    </Box>
  )
}

const DetailsTab = ({ lines }) => {
  return (
    <Box sx={{ padding: 2, maxHeight: '50vh' }}>
      {/* Parts Ordered Table */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Parts Ordered
      </Typography>
      <Box sx={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', backgroundColor: '#f9f9f9' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Desc</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Part Number</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Qty</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'right' }}>Unit Price</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'right' }}>Cost</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line) => (
              <tr key={line.ResponseDetailId}>
                <td style={{ padding: '10px', textAlign: 'left' }}>{line.Parts}</td>
                <td style={{ padding: '10px', textAlign: 'left' }}>{line.PartNumber}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>{line.Qty}</td>
                <td style={{ padding: '10px', textAlign: 'right' }}>
                  {line.Confirmed !== null ? `£${line.UnitPrice.toFixed(2)}` : 'Not confirmed.'}
                </td>
                <td style={{ padding: '10px', textAlign: 'right' }}>
                  {line.Confirmed !== null ? `£${line.Cost.toFixed(2)}` : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <div className="mt-2" />
      {/* Delivery Date and Created Date */}
      {!!lines?.[0]?.DeliveryDate && (
        <Typography variant="body1">
          <strong>Delivery Date:</strong> {dayjs(lines[0].DeliveryDate).format('DD/MM/YYYY')}
        </Typography>
      )}
      <Typography variant="body1">
        <strong>Created:</strong> {dayjs(lines[0].DatetimeCreated).format('DD/MM/YYYY, HH:mm')}
      </Typography>
    </Box>
  )
}

const MessagesTab = ({ enquiry, messages, locked, refetch, isLockedByCurrentUser }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, display: 'flex', flexDirection: 'column-reverse', px: 2 }}>
        {messages && messages.length === 0 && <Typography>No messages found for this enquiry.</Typography>}
        {messages && [...messages].reverse().map((message) => <Message key={message.MessageId} message={message} />)}
      </Box>

      {!locked || isLockedByCurrentUser ? (
        <ComposeMessage enqId={enquiry.EnquiryId} refetchMessages={refetch} />
      ) : (
        <Typography variant="body2" sx={{ textAlign: 'center', color: 'gray' }}>
          You cannot send messages on a locked enquiry.
        </Typography>
      )}
    </Box>
  )
}

const Message = ({ message }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const hasImage = !!message.BlobStorageId
  const hasText = !!message.Text
  const isImageOnly = hasImage && !hasText

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = await getImageFromBlobId(message.BlobStorageId)
        setImageUrl(url)
      } catch (e) {
        console.log(e)
        console.log(message)
        setImageUrl('')
      }
    }

    if (imageUrl) return
    fetchImage()
  }, [message.BlobStorageId])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: message.Factor ? 'flex-end' : 'flex-start',
        mb: 1
      }}
    >
      <Box
        sx={{
          maxWidth: '70%',
          p: 2,
          borderRadius: 2,
          backgroundColor: message.Factor ? 'dodgerblue' : '#e0e0e0',
          color: message.Factor ? 'white' : 'black'
        }}
      >
        {!!message.Username && (
          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
            {message.Username}
          </Typography>
        )}
        {hasImage && (
          <div
            className="rounded-md w-[250px] h-[250px] bg-contain bg-no-repeat bg-center border border-gray-300 flex items-center justify-center"
            style={{ backgroundColor: 'white', backgroundImage: `url(${imageUrl || '/img/whitepixel.png'})` }}
            onClick={() => (imageUrl ? NiceModal.show('lightboxModal', { url: imageUrl }) : null)}
          >
            {!imageUrl && <Spinner />}
          </div>
        )}
        {hasText && <Typography variant="body1">{message.Text}</Typography>}
        <Typography variant="caption" sx={{ display: 'block', mt: 1, textAlign: 'right' }}>
          {message.Datetime ? dayjs(message.Datetime).format('DD/MM/YYYY HH:mm') : 'Unknown date'}
        </Typography>
      </Box>
    </Box>
  )
}

export default EnquiryDetailsView
