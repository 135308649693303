import NiceModal from '@ebay/nice-modal-react'

import EnquiryDetailsModal from '../components/EnquiryDetailsModal'
import VrmUsageModal from '../components/settings/VrmUsageModal'
import VrmAddModal from '../components/settings/VrmAddModal'
import DeleteModal from '../components/DeleteModal'
import PartListAddModal from '../components/settings/PartListAddModal'
import PartListEditModal from '../components/settings/PartListEditModal'
import LightboxModal from '../components/LightboxModal'

NiceModal.register('enquiryDetailsModal', EnquiryDetailsModal)
NiceModal.register('vrmUsageModal', VrmUsageModal)
NiceModal.register('vrmAddModal', VrmAddModal)
NiceModal.register('deleteModal', DeleteModal)
NiceModal.register('partListAddModal', PartListAddModal)
NiceModal.register('partListEditModal', PartListEditModal)
NiceModal.register('lightboxModal', LightboxModal)
