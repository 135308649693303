import toast from 'react-hot-toast'

// https://stackoverflow.com/a/18650249
export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const groupByKey = (key, arr) => {
  return arr.reduce((acc, cur) => {
    if (!acc[cur[key]]) acc[cur[key]] = []
    acc[cur[key]].push(cur)
    return acc
  }, {})
}

export const objectToFormData = (obj) => {
  let formData = new FormData()

  for (const key in obj) {
    formData.append(key, obj[key])
  }

  return formData
}

export const objectToUrlEncoded = (obj) => {
  let body = []

  for (const key in obj) {
    var encodedKey = encodeURIComponent(key)
    var encodedValue = encodeURIComponent(obj[key])
    body.push(encodedKey + '=' + encodedValue)
  }

  const ret = body.join('&')
  return ret
}

export const successToast = (msg) =>
  toast.success(msg, {
    position: 'bottom-center',
    duration: 3000,
    style: {
      borderRadius: '6px',
      background: '#333',
      color: '#fff',
      maxWidth: '600px'
    }
  })

export function removeDuplicates(arr, key) {
  const obj = {}
  arr.forEach((x) => (obj[x[key]] = x))
  return Object.values(obj)
}
