import NiceModal from '@ebay/nice-modal-react'
import { Autocomplete, Button, Card, Input, Switch, TextField } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import useApi from '../../hooks/useApi'
import { ModalContainerAnimateDown } from '../gsys-ui/ModalContainer/ModalContainer'
import moment from 'moment'
import haxios from '../../lib/haxios'
import VirtualisedAutocomplete from '../VirtualisedAutocomplete'
import { successToast } from '../../lib/util'

const PartListEditModal = NiceModal.create(({ initialGroup, initialList, refetchPartLists }) => {
  const modalContainer = useRef(null)
  const [partGroup, setPartGroup] = useState(initialGroup)
  const [partList, setPartList] = useState(initialList.join(', '))

  const handleClose = () => {
    modalContainer.current?.closeModal()
  }

  const handleSave = async () => {
    try {
      await haxios.put(`/connectplus/partlist/update`, {
        name: partGroup,
        list: partList.split(',').map((el) => el.trim())
      })

      await refetchPartLists()
      handleClose()
      successToast(`Part list ${partGroup} has been updated successfully.`)
    } catch (e) {
      console.log(e)
    }
  }

  const handleDelete = () => {
    const submit = async () => {
      await haxios.delete(`/connectplus/partlist/delete`, { data: { PartGroup: initialGroup } })
      await refetchPartLists()
      handleClose()
      successToast(`Part list ${initialGroup} has been deleted successfully.`)
    }

    NiceModal.show('deleteModal', { text: 'Are you sure you want to delete this part list?', submit })
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card className="w-[500px]">
        <div className="flex justify-between items-center p-4 border-b border-b-gray-300">
          <div className="text-2xl font-semibold">Edit part list</div>
          <div className="space-x-2">
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDelete} color="error">
              Delete
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>

        <div className="p-4 space-y-4">
          <div className="text-lg font-semibold">{initialGroup}</div>
          <TextField
            fullWidth
            label="Part List Name"
            value={partGroup}
            onChange={(e) => setPartGroup(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Part Items (comma separated)"
            value={partList}
            onChange={(e) => setPartList(e.target.value)}
          />
        </div>
      </Card>
    </ModalContainerAnimateDown>
  )
})

export default PartListEditModal
