import NiceModal from '@ebay/nice-modal-react'
import { Autocomplete, Button, Card, Input, Switch, TextField } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import useApi from '../../hooks/useApi'
import { ModalContainerAnimateDown } from '../gsys-ui/ModalContainer/ModalContainer'
import moment from 'moment'
import haxios from '../../lib/haxios'
import VirtualisedAutocomplete from '../VirtualisedAutocomplete'
import { successToast } from '../../lib/util'

const VrmAddModal = NiceModal.create(({ refetchUsers }) => {
  const modalContainer = useRef(null)
  const [limitValue, setLimitValue] = useState(0)
  const [allowedValue, setAllowedValue] = useState(true)
  const [selectedAcct, setSelectedAcct] = useState(null)

  const { isLoading, data, refetch } = useApi({
    url: `/connectplus/vrmUsages`,
    data: {}
  })

  if (isLoading || !data) return null

  const handleClose = () => {
    modalContainer.current?.closeModal()
  }

  const handleSave = async () => {
    try {
      await haxios.put(`/connectplus/vrmAllowances/${selectedAcct.id}`, {
        Allowed: allowedValue,
        Limit: parseInt(limitValue)
      })

      await refetchUsers()
      handleClose()
      successToast(`User ${selectedAcct.id} has been added successfully.`)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer}>
      <Card className="w-[500px]">
        <div className="flex justify-between items-center p-4 border-b border-b-gray-300">
          <div className="text-2xl font-semibold">Add VRM user</div>
          <div className="space-x-2">
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Add
            </Button>
          </div>
        </div>

        <div className="p-4 space-y-4">
          <div>
            <SelectAcct value={selectedAcct} onChange={setSelectedAcct} />
          </div>
          <div className="flex">
            <div className="flex-1">
              <div className="text-sm">Limit</div>
              <Input value={limitValue} onChange={(e) => setLimitValue(e.target.value)} variant="outlined" />
            </div>
            <div className="flex-1">
              <div className="text-sm">Allowed</div>
              <Switch checked={allowedValue} onChange={(e) => setAllowedValue(e.target.checked)} />
            </div>
          </div>
        </div>
      </Card>
    </ModalContainerAnimateDown>
  )
})

const SelectAcct = ({ value, onChange }) => {
  const { isLoading, data, refetch } = useApi({ url: `/connectplus/getAccts` })

  if (isLoading || !data)
    return (
      <Autocomplete
        value="Loading..."
        options={[]}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} disabled />}
      />
    )

  const values = data.map((el) => ({ label: `[${el.KeyCode}] ${el.Name}`, id: el.KeyCode }))

  return <VirtualisedAutocomplete value={value} onChange={(e, newValue) => onChange(newValue)} options={values} />
}

export default VrmAddModal
