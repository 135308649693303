import { useState, useRef } from 'react'
import { PaperAirplaneIcon, PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline'

import Spinner from './gsys-ui/Spinner/Spinner'
import { sendMessage } from '../lib/api'

const ComposeMessage = ({ enqId, refetchMessages }) => {
  const [isSending, setSending] = useState(null)
  const [messageText, setMessageText] = useState('')
  const [pickedImage, setPickedImage] = useState(false)
  const [error, setError] = useState(null)
  const pickerRef = useRef(null)

  const handlePressSend = async () => {
    if (!messageText && !pickedImage) return
    setSending(true)

    try {
      await sendMessage(enqId, messageText, pickedImage)
      setPickedImage(null)
      setMessageText('')
      await refetchMessages()
      setError(null)
    } catch (e) {
      setError('The message failed to send.')
    }

    setSending(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handlePressSend()
    }
  }

  const handlePressAttach = () => {
    pickerRef.current.click()
  }

  const handlePressRemoveAttach = () => {
    setPickedImage(false)
    pickerRef.current.value = ''
  }

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    const MAX_SIZE = 4 * 1024 * 1024
    console.log(file.size)

    if (file.size > MAX_SIZE) {
      setError('Images must be smaller than 4MB')
      return
    }

    setError(null)
    setPickedImage(file)
  }

  const handlePaste = (e) => {
    const items = e.clipboardData?.items
    if (!items) return

    for (const item of items) {
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile()
        setPickedImage(file)
        break
      }
    }
  }

  return (
    <div className="p-3 border-t border-gray-300">
      {(pickedImage || error) && (
        <div className="flex justify-between items-center mb-2">
          <div className="text-red-500">{error}</div>
          <div className="flex items-center">
            {pickedImage && (
              <>
                <img
                  className="object-cover w-10 h-10 rounded-lg"
                  src={URL.createObjectURL(pickedImage)}
                  alt="picked image"
                />
                <XMarkIcon
                  className="ml-1 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
                  onClick={handlePressRemoveAttach}
                />
              </>
            )}
          </div>
        </div>
      )}
      <div className="flex items-center">
        <div className="flex items-end flex-0">
          <input type="file" className="hidden" accept="image/*" ref={pickerRef} onChange={handleChangeFile} />
          <PaperClipIcon
            className="mr-3 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
            onClick={handlePressAttach}
          />
        </div>
        <textarea
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 outline-none resize-none"
          placeholder="Write a message..."
          rows={messageText.split('\n').length}
          onPaste={handlePaste}
        />
        <div className="flex items-end flex-0">
          {isSending ? (
            <div className="mr-1.5">
              <Spinner size={20} />
            </div>
          ) : (
            <PaperAirplaneIcon
              className="ml-3 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
              onClick={handlePressSend}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ComposeMessage
